import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import authService from '@/services/auth';

const adminRoutes = [
  {
    path: '/configuracoes/usuarios',
    name: 'usuarios-lista',
    component: () => import('../views/admin/admin/usuarios/ListaView.vue'),
  },
  {
    path: '/configuracoes/usuarios/:id',
    name: 'usuarios',
    component: () => import('../views/admin/admin/usuarios/UsuarioView.vue'),
  },
  {
    path: '/configuracoes/aplicacoes',
    name: 'aplicacoes-lista',
    component: () => import('../views/admin/admin/projetos/ListaView.vue'),
  },
  {
    path: '/configuracoes/aplicacoes/:id',
    name: 'aplicacao',
    component: () => import('../views/admin/admin/projetos/ProjetoView.vue'),
  },

  {
    path: '/',
    name: 'admin-opcoes',
    component: () => import('../views/admin/AdminView.vue'),
  },
].map((item) => {
  item.path = '/admin' + item.path;
  item.beforeEnter = (to, from, next) => {
    if (!useAuthStore().user?.isAdmin) next('/');
    next();
  };
  return item;
});

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/idrc',
      name: 'idrc',
      component: () => import('../views/IdRCView.vue'),
    },
    {
      path: '/idrc-sucesso',
      name: 'idrc-sucesso',
      component: () => import('../views/IdRCSucessoView.vue'),
    },
    {
      path: '/',
      name: 'home',
      component: () => import('../views/ProjetosView.vue'),
    },
    {
      path: '/nivel-baixo',
      name: 'nivel-baixo',
      component: () => import('../views/nivelDeGarantia/NivelBaixoView.vue'),
    },
    {
      path: '/nivel-substancial',
      name: 'nivel-substancial',
      component: () => import('../views/nivelDeGarantia/NivelSubstancialView.vue'),
    },
    {
      path: '/nivel-alto',
      name: 'nivel-alto',
      component: () => import('../views/nivelDeGarantia/NivelAltoView.vue'),
    },

    ...adminRoutes,
  ],
});

router.beforeEach(async (to) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/', '/bootstrap', '/sem-acesso', '/idrc', '/idrc-sucesso'];
  const authRequired = !publicPages.includes(to.path);
  const auth = useAuthStore();
  const { logout } = to.query;

  if (logout) {
    await auth.logoutCallback();
    return '/';
  }

  if (auth.token || authRequired) {
    const authenticated = await auth.verifyLogin();

    if (!authenticated) {
      auth.logoutCallback();
      return '/';
    }
    auth.user = await authService.getSelfData();
    if (to.path === '/nivel-baixo') await auth.ensureLOA('baixo');
    if (to.path === '/nivel-substancial') await auth.ensureLOA('substancial');
    if (to.path === '/nivel-alto') await auth.ensureLOA('alto');
  }
});

export default router;
