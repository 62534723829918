import { fetchWrapper } from '@/helpers/fetch-wrapper';

const baseUrl = `${import.meta.env.VITE_API_URL}`;

const authService = {
  verifyAndUpdateToken: () => {
    return fetchWrapper.get(`${baseUrl}/verify-and-update-token`, null, null);
  },
  getSelfData: () => {
    return fetchWrapper.get(`${baseUrl}/get-own-data`, null, null, null);
  },
};

export default authService;
